* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.main-login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.login-form-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    height: 60vh;
    border: 2px solid rgba(255, 125, 6, 1);
    border-radius: 8px;
    background-color: rgba(255, 125, 6, 0.85);
}

#login-logo {
    width: 100px;
    height: 100px;
}

#login-button {
    background-color: white;
}

#login-button:hover {
    border: 1px solid rgba(255, 125, 6, 1);;
    color: rgba(255, 125, 6, 1);;
}

header {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px;
    background-color: #3F72AF !important;
  }
  
  .ant-layout {
    background-color: #DBE2EF;
  }
  
  tr th {
    background-color: #fef3e264 !important;
  }
  
  .ant-card-actions span {
    cursor: default !important;
  }
  
  .ant-card-actions span:hover {
    cursor: default !important; 
    color: rgba(0, 0, 0, 0.45) !important;
  }
  
  .ant-drawer-mask {
    border-radius: 8px;
  }
  
  .ant-card {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.24) 0px 1px 2px !important;
  }
  
  .ant-table-expanded-row {
    box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px 0px inset, rgba(0, 0, 0, 0.1) 0px 4px 4px 0px inset;
  }
  
  .ant-table-header {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 2px;
  }
  
  .ant-drawer-content {
    border-radius: 0px 8px 8px 0px;
  }
  
  .striped-row {
    background-color: rgb(249, 249, 249);
  }
  
  .statistic .ant-tag {
    margin-inline-end: 0px;
  }